import foosball from "./foosball.png";
import cornhole from "./cornhole.png";
import tabletennis from "./tabletennis.png";
import soccer from "./soccer.png";

export function icons() {
  return {
    foosball,
    cornhole,
    tabletennis,
    soccer,
  };
}
